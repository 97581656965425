import {STON} from "../constants/names";

export const reformImageUrl = (url: string): string => {

    if (url.includes(STON)) {
        return url
        // return url + ADD_URL
    } else {
        return url
    }
}